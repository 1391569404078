import { envConfig } from "@configs/env";
// @ts-ignore
import themeColors from "kz-ui-sdk/dist/theme.config.js";
/**
 * Get the theme color configuration based on the brand key
 */
export const getThemeColorConfig = () => {
  const themePresetKey = envConfig.brand.theme;
  // @ts-ignore
  return themeColors[themePresetKey] || themeColors["dark-purple"];
};

/**
 * Get the theme asset path based on the theme color configuration
 * Asset path should be relative to the theme folder
 * Examples
 * - Path "/images/logo.png" returns "/images/gold/logo.png"
 * - Path "/logo.png" returns "/images/gold/logo.png"
 * @param assetPath
 */
export const getThemeAsset = (assetPath: string) => {
  const themeConfig = getThemeColorConfig();
  // standardize the asset path
  // remove leading slash
  // remove /images/ prefix if exists
  assetPath = assetPath.startsWith("/") ? assetPath.slice(1) : assetPath;
  assetPath = assetPath.startsWith("images/") ? assetPath.slice(7) : assetPath;
  // add prefix to the asset path
  return `/images/themes/${themeConfig.name}/${assetPath}`;
};
