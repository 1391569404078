import { AppConfiguration, CountryCurrency, CountryEnvKey, CountryPhoneCode, DeployCountry, Language } from "@types";
import { REG_PHONE_10_DIGITS, REG_PHONE_11_DIGITS } from "kz-ui-sdk";

export const appConfig: AppConfiguration = {
  countries: {
    [DeployCountry.TH]: {
      language: Language.TH,
      phoneCode: CountryPhoneCode.TH,
      envKey: CountryEnvKey.TH,
      code: DeployCountry.TH,
      currency: CountryCurrency.TH,
      currencySymbol: "฿",
      postback: {
        campaignId: "",
        siteUrl: window.location.origin,
      },
      validators: {
        phone: REG_PHONE_10_DIGITS,
      },
    },
    [DeployCountry.PH]: {
      language: Language.EN,
      phoneCode: CountryPhoneCode.PH,
      envKey: CountryEnvKey.PH,
      code: DeployCountry.PH,
      currency: CountryCurrency.PH,
      currencySymbol: "₱",
      postback: {
        campaignId: "",
        siteUrl: window.location.origin,
      },
      validators: {
        phone: REG_PHONE_11_DIGITS,
      },
    },
  },
  server: {
    baseURL: import.meta.env.VITE_API_BASE_URI as string,
  },
} as const;
