export interface AppConfiguration<TDeployCountry extends string = DeployCountry> {
  countries: Record<TDeployCountry, CountryConfiguration>;
  server: ServerConfiguration;
}

export interface BrandConfiguration {
  name: string;
  theme: string;
  key: string;
  group: string;
  logoMaxHeight: string;
}

/**
 * Environment configuration interface.
 * Represents the environment configuration for the application.
 * Automatically generated from the country-based configuration.
 */
export interface EnvConfiguration {
  /**
   * Configuration for the brand.
   */
  brand: BrandConfiguration;
  /**
   * The environment in which the application is deployed.
   */
  deploymentEnv: DeployEnv;
  /**
   * The unique identifier for the account.
   */
  accountId: string;
  /**
   * The language used in the application.
   */
  language: Language;
  /**
   * The phone code for the country.
   */
  phoneCode: number;
  /**
   * The country code where the application is deployed.
   */
  countryCode: DeployCountry;
  /**
   * The environment key for the country.
   */
  envKey: CountryEnvKey;
  /**
   * The currency used in the country.
   */
  currency: CountryCurrency;
  /**
   * The symbol for the currency.
   */
  currencySymbol: string;
  /**
   * Configuration for postback.
   */
  postback: PostbackConfiguration;
  /**
   * Validators for phone number.
   */
  validators: {
    /**
     * Regular expression for phone number validation.
     */
    phone: RegExp;
  };
}

export interface CountryConfiguration {
  language: Language;
  phoneCode: number;
  code: DeployCountry;
  envKey: CountryEnvKey;
  currency: CountryCurrency;
  currencySymbol: string;
  postback: PostbackConfiguration;
  validators: {
    phone: RegExp;
  };
}

export interface ServerConfiguration {
  baseURL: string;
}

export interface PostbackConfiguration {
  campaignId: string;
  siteUrl: string;
}

export enum CountryPhoneCode {
  TH = 66,
  PH = 63,
}

export enum DeployCountry {
  TH = "th",
  PH = "ph",
}

export enum Language {
  TH = "th",
  EN = "en",
}

export enum CountryEnvKey {
  TH = "TH",
  PH = "PH",
}

export enum CountryCurrency {
  TH = "THB",
  PH = "PHP",
}

export enum DeployEnv {
  Production = "production",
  Staging = "staging",
  Local = "local",
}
