import {
  FundingMethodDepositMethod,
  FundingMethodKey,
  FundingMethodModel,
  FundingMethodStatus,
  FundingMethodType,
  bnOrZero,
} from "kz-ui-sdk";

export const MINT_METHOD: FundingMethodModel = {
  createdAt: undefined,
  deletedAt: undefined,
  max: bnOrZero(10_000_000_000),
  min: bnOrZero(100),
  updatedAt: undefined,
  accountId: null,
  banner: {
    url: "/images/mint-logo.png",
    mimeType: "",
  },
  bannerId: "",
  creditReqAmount: true,
  currency: "",
  disabled: false,
  fundingProviderId: "",
  id: "",
  isDefault: false,
  key: FundingMethodKey.MintDeposit,
  method: FundingMethodDepositMethod.QrCode,
  name: "Mint Deposit",
  proofRequired: false,
  skipAmountInput: false,
  sort: 0,
  status: FundingMethodStatus.Active,
  type: FundingMethodType.Deposit,
};
