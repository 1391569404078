import { envConfig } from "@configs/env";

export const formatTimer = (
  timeout: number,
  leadingZero = {
    minutes: true,
    seconds: true,
  },
) => {
  const minutes = Math.floor(timeout / 60);
  const seconds = timeout % 60;

  let formattedMinutes = minutes.toString();
  let formattedSeconds = seconds.toString();

  if (leadingZero.minutes && minutes < 10) {
    formattedMinutes = `0${minutes}`;
  }

  if (leadingZero.seconds && seconds < 10) {
    formattedSeconds = `0${seconds}`;
  }

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const getBrandResource = (path: string) => {
  // remove leading slash
  if (path.startsWith("/")) {
    path = path.slice(1);
  }
  return `/brands/${envConfig.brand.key}/${path}`;
};

export const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
