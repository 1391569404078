/**
 * Environment configuration file.
 * Adds more information than .env file.
 * List of available configs:
 */

import { appConfig } from "@configs/application";
import { BrandConfiguration, CountryConfiguration, DeployCountry, DeployEnv, EnvConfiguration } from "@types";

/**
 * This class is responsible for checking and managing environment configurations.
 * - Managing brand configurations
 * - Handling country-specific settings
 */
class EnvChecker {
  private static instance: EnvChecker;
  private env: EnvConfiguration;

  private constructor() {
    const currentCountry = import.meta.env.VITE_CURRENT_COUNTRY as DeployCountry;

    if (!currentCountry) {
      throw Error("Current country is not defined, please check VITE_CURRENT_COUNTRY in .env file");
    }

    // Find the deploying env from the appConfig
    const countryConfig = appConfig.countries[currentCountry] as CountryConfiguration;

    if (!countryConfig) {
      throw Error("Country config is not defined, please check application.ts file");
    }

    // Brand config
    const brandConfig: BrandConfiguration = {
      name: import.meta.env.VITE_BRAND_NAME,
      theme: import.meta.env.VITE_THEME_PRESET,
      key: import.meta.env.VITE_BRAND_KEY,
      group: import.meta.env.VITE_BRAND_GROUP,
      logoMaxHeight: import.meta.env.VITE_BRAND_LOGO_MAX_HEIGHT || "64px",
    };

    this.env = {
      deploymentEnv: import.meta.env.VITE_DEPLOY_ENV as DeployEnv,
      brand: brandConfig,
      accountId: import.meta.env.VITE_ACCOUNT_ID,
      countryCode: countryConfig.code,
      language: countryConfig.language,
      phoneCode: countryConfig.phoneCode,
      envKey: countryConfig.envKey,
      currency: countryConfig.currency,
      currencySymbol: countryConfig.currencySymbol,
      postback: countryConfig.postback,
      validators: countryConfig.validators,
    };
  }

  public static getInstance(): EnvChecker {
    if (!EnvChecker.instance) {
      EnvChecker.instance = new EnvChecker();
    }
    return EnvChecker.instance;
  }

  public getDeployEnv() {
    return this.env;
  }
}

const deployEnvChecker = EnvChecker.getInstance();

export const getDeployEnv = deployEnvChecker.getDeployEnv.bind(deployEnvChecker);

export const isProd = () => {
  return import.meta.env.PROD && import.meta.env.VITE_DEPLOY_ENV === "production";
};

export const envConfig = getDeployEnv();
