import { authApi, publicApi } from "@api";
import { envConfig } from "@configs/env";
import { STORAGE_KEY } from "@constants";
import { fork, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import logger from "@utils/logger";
import { execQuery } from "@utils/saga";
import { AuthToken } from "kz-ui-sdk";
import moment from "moment";
import authSlice from "./slices";
import { InitAuthPayload } from "./types";

function* loadAccessToken() {
  try {
    yield* execQuery(
      publicApi.endpoints.getInfo.initiate({
        accountId: envConfig.accountId,
      }),
    );

    logger._console.log("loadAccessToken");
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    if (typeof accessToken !== "string") return null;

    const [, _payload] = accessToken.split(".");
    const payload = JSON.parse(atob(_payload));

    if (typeof payload.exp !== "number") throw new Error("cannot validate token expiry");

    const expiresAt = moment.unix(payload.exp);
    if (expiresAt.isBefore(moment())) {
      logger._console.debug("token expired");
      return null;
    }

    const profileResponse = yield* execQuery(authApi.endpoints.getProfileWithToken.initiate(accessToken));
    logger._console.log("init profile", { profileResponse });
    if (!profileResponse) {
      logger._console.debug("cannot load profile from token");
      return null;
    }

    const result: InitAuthPayload = {
      self: profileResponse.self,
      accounts: profileResponse.accounts,
      token: {
        access_token: accessToken,
        expires_at: payload.exp,
        token_type: "bearer",
      },
      bankInfos: profileResponse.bankInfos,
      contactLink: profileResponse.contactLink,
      latestDepositAmount: profileResponse.latestDepositAmount,
    };
    return result;
  } catch (error) {
    logger._console.error("error loading access token");
    logger._console.error(error);
    return null;
  }
}

function* handleAction(action: PayloadAction<AuthToken>) {
  logger._console.log("handle action", action, moment.unix(action.payload.expires_at).isAfter(moment()));
  // If the token is valid and not expired, update the profile/bank account
  if (action.payload.access_token && moment.unix(action.payload.expires_at).isAfter(moment())) {
    // Update the profile
    logger._console.log({ action });
    const profile = yield* execQuery(authApi.endpoints.getProfile.initiate());
    logger._console.log("update profile", profile);
    yield put(authSlice.actions.updateProfile(profile));
  }
}

function* init() {
  const result = yield* loadAccessToken();
  if (!result) {
    yield put(authSlice.actions.initAuthState(null));
  } else {
    yield put(authSlice.actions.initAuthState(result));
  }
}

function* saga() {
  yield takeLatest(authSlice.actions.updateAccessToken.type, handleAction);
  yield fork(init);
}

export default saga;
