import { publicApi } from "@api";
import BottomNavigation from "@components/navigation/BottomNavigation";
import IframeDialog from "@components/utils/IframeDialog";
import { LazyLoadComponent } from "@components/utils/LazyLoadComponent";
import { openBankAccountDrawer } from "@components/utils/OnboardingDrawer/utils";
import UserModeRenderer from "@components/utils/UserModeRenderer";
import { envConfig } from "@configs/env";
import { Paths } from "@constants";
import useAuthSession from "@hooks/useAuthSession";
import useTypedSelector from "@hooks/useTypedSelector";
import { AuthStatus, UserMode } from "@types";
import { resolveRegisterRequestError } from "@views/LoginPage/utils";
import {
  Page,
  PageContent,
  PageFooter,
  SW_MEMBER_ID_STORAGE_KEY,
  cn,
  updatePushSubscription,
  useHandleApiResponse,
  useResetScroll,
} from "kz-ui-sdk";
import { lazy, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Outlet, matchPath, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../PageHeader";

interface PrivateRouteProps {
  mode?: UserMode;
}

const ignoreLayoutInRoutes = [
  Paths.PRIVATE.UPDATE_BANK_FORCE,
  Paths.PRIVATE.UPDATE_PIN_FORCE,
  Paths.PRIVATE.DEPOSIT_CALLBACK,
];

const ignorePaddingBottomInRoutes: string[] = [Paths.PRIVATE.DAILY_REWARD];

const LazyDepositDrawer = lazy(() => import(/* webpackChunkName: "DepositDrawer" */ "@components/utils/DepositDrawer"));

const LazyWithdrawDrawer = lazy(
  () => import(/* webpackChunkName: "WithdrawDrawer" */ "@components/utils/WithdrawDrawer"),
);
const LazyOnboardingDrawer = lazy(
  () => import(/* webpackChunkName: "OnboardingDrawer" */ "@components/utils/OnboardingDrawer"),
);

/**
 * Private route layout
 * Display header, content, footer, and bottom navigation
 * Protected routes that require authentication:
 * - Redirect to login page if unauthenticated
 * - Redirect to reset password page if password expired
 * - Redirect to update bank page if no default bank
 */
const PrivateRouteLayout = ({ mode }: PrivateRouteProps) => {
  const refMainBody = useRef<HTMLDivElement>(null);
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const { status: authStatus, self, oauth, bankAccount, userMode } = useAuthSession();
  const [createRegisterMutation] = publicApi.useCreateRegisterMutation();
  const { handleApiResponse } = useHandleApiResponse({ toast });
  const { t } = useTranslation();
  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);
  const [isReadyOnboardDrawer, setIsReadyOnboardDrawer] = useState(false);

  useEffect(() => {
    // Redirect to login page if unauthenticated or authenticating
    if (authStatus === AuthStatus.Unauthenticated && mode === UserMode.MEMBER) {
      navigate(Paths.PRIVATE.HOME);
    }
    // Redirect to reset password page if password expired
    if (authStatus === AuthStatus.PasswordExpired && self?.member?.phone) {
      // If user is already in reset password page, show toast message and send OTP
      if (pathname === Paths.PRIVATE.UPDATE_PIN_FORCE) {
        toast.error(t("Your PIN has expired. Please reset your PIN."));
        createRegisterMutation({
          phone: self?.member?.phone ?? "",
          accountId: envConfig.accountId,
        }).then((res) => {
          handleApiResponse(res, {
            errorMessageResolver: (error) => resolveRegisterRequestError(error, t),
          });
        });
      } else {
        // Redirect to reset password page
        navigate(Paths.PRIVATE.UPDATE_PIN_FORCE, {
          replace: true,
        });
      }
      return;
    }
  }, [
    authStatus,
    createRegisterMutation,
    bankAccount,
    handleApiResponse,
    navigate,
    pathname,
    self?.member?.phone,
    t,
    mode,
  ]);

  // Update push notification subscription
  useEffect(() => {
    if (authStatus === AuthStatus.Authenticated) {
      if (!!self && !!oauth && !!self.member?.id) {
        const registeredMemberId = sessionStorage.getItem(SW_MEMBER_ID_STORAGE_KEY);
        // if member id is not registered or different, update notification subscription
        if (!registeredMemberId || registeredMemberId !== self.member.id) {
          updatePushSubscription({
            memberId: self.member.id,
            accountId: envConfig.accountId,
            notificationURI: import.meta.env.VITE_PUSH_NOTIFICATION_URI,
            notificationTestParam: import.meta.env.VITE_TEST_NOTIFICATION_PARAM,
            publicUrl: import.meta.env.VITE_PUBLIC_URL,
          }).then(() => {});
        }
      }
    }
  }, [authStatus, oauth, self]);

  // Display layout for all routes except ignored routes
  const displayLayout = useMemo(() => {
    return !ignoreLayoutInRoutes.some((route) => matchPath(route, pathname));
  }, [pathname]);

  // Display footer for all routes except ignored routes
  const displayPaddingBottom = useMemo(() => {
    return !ignorePaddingBottomInRoutes.some((route) => matchPath(route, pathname));
  }, [pathname]);

  // Reset scroll position on route change
  useResetScroll(refMainBody, pathname);

  // Force update bank account
  useEffect(() => {
    if (
      authStatus === AuthStatus.Authenticated &&
      bankAccount === null &&
      pathname !== Paths.PRIVATE.UPDATE_BANK_FORCE &&
      isReadyOnboardDrawer
    ) {
      setTimeout(() => {
        openBankAccountDrawer();
      }, 1000);
    }
  }, [authStatus, bankAccount, isReadyOnboardDrawer, pathname]);

  // Show loading spinner/blank if authenticating
  if (authStatus === AuthStatus.Authenticating) return null;

  return (
    <Page className="mx-auto">
      {displayLayout && (
        <PageHeader
          className={cn({
            "top-[40px]": isDisplayAppInstaller,
          })}
        />
      )}

      <PageContent
        ref={refMainBody}
        className={cn("scrollbar-primary page-content mt-[50px] px-5 pt-2.5", {
          "!mt-0 !p-0": !displayLayout,
          "!mt-[90px]": isDisplayAppInstaller && displayLayout,
          "!mt-[40px]": isDisplayAppInstaller && !displayLayout,
          "pb-28": displayPaddingBottom,
          "pb-[56px]": !displayPaddingBottom,
        })}
      >
        <Outlet />

        <LazyLoadComponent
          delay={200}
          onLoad={() => {
            setIsReadyOnboardDrawer(true);
          }}
        >
          <LazyOnboardingDrawer />
        </LazyLoadComponent>
      </PageContent>

      <UserModeRenderer mode={UserMode.MEMBER}>
        <LazyLoadComponent>
          <LazyDepositDrawer />
          <LazyWithdrawDrawer />
        </LazyLoadComponent>
      </UserModeRenderer>

      <IframeDialog />

      {displayLayout && (
        <PageFooter
          fixed
          className="z-10"
        >
          <BottomNavigation
            className="mx-auto max-w-md"
            userMode={userMode}
          />
        </PageFooter>
      )}
    </Page>
  );
};

export default PrivateRouteLayout;
