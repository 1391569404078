import { createSWRegistration } from "kz-ui-sdk";
import { getSWCacheVersion } from "@utils/sw";


export const swRegistration = createSWRegistration(window, {
  accountId: import.meta.env.VITE_ACCOUNT_ID,
  publicUrl: import.meta.env.VITE_PUBLIC_URL ?? "",
  fileName: "service-worker.js",
  notificationURI: import.meta.env.VITE_PUSH_NOTIFICATION_URI,
  notificationInfoEndpoint: "/notification/info",
  env: import.meta.env.VITE_DEBUG_NOTIFICATION ? 'production' : import.meta.env.MODE,
  getVersion: getSWCacheVersion,
})
